/*
 * @Author: Coan
 * @Date: 2023-04-12 14:56:27
 * @LastEditors: Coan
 * @LastEditTime: 2023-04-18 23:37:33
 * @FilePath: /rebuild_web_new/src/i18n/en.js
 * @Description:
 */
export default {
  官网: 'Official Website',
  qrcode: {
    server: 'Service Number',
    company: 'Enterprise WeChat',
    service: 'Sweep WeChat code to add customer service',
    serviceQ: 'Contact QQ'
  },
  photoList: 'Photo List',
  photoSize: 'Photo size',
  automaticModelingTip: 'Automatic modeling after uploading',
  // 联系客服提示
  service: {
    customerText1: 'To get faster upload speed and better model effect, please contact customer service, scan the QR code below to add wechat or QQ for communication, thank you for your cooperation',
    contentTips: 'If you need faster and better support, please attach your request with files to our mailbox: zhu.yani@star.vision/chen.junrui@star.vision. Thank you for your coorperation.',
    bottomTips: 'Please scan the code to add customer service WeChat or contact QQ：2867488797'
  },
  notice: {
    unbound: 'In the hard work, please wait patiently!',
    requiredValue: 'Please fill in the required fields!',
    success: 'The operation is successful, please wait patiently!'
  },
  nav: {
    案例中心: 'Case center',
    解决方案: 'Solution',
    体验入口: 'Experience entrance',
    联系我们: 'Contact Us',
    合作伙伴: 'Partners',
    语言: 'Language',
    搜索占位: 'Search the 3D model you want'
  },
  language: {
    中文: '中文',
    英文: 'ENGLISH'
  },
  btns: {
    去体验: 'To experience',
    查看更多: 'Read More',
    马上去体验: 'Experience it right away',
    提交: 'Submit',
    创建模型: 'Create Model',
    下一步: 'Next Step',
    上一步: 'Last Step',
    上传: 'Upload',
    点击上传: 'Click For Upload'
  },
  status: {
    准备中: 'Preparing',
    构建中: 'Building',
    构建成功: 'View',
    构建失败: 'Failure',
    全部: 'All'
  },
  filters: {
    只看星标: 'Star',
    处理列表: 'Table',
    终端: 'Table',
    状态: 'Status',
    排序: 'Sout',
    列表: 'Table',
  },
  label: {
    name: 'Name',
    companyName: 'Company Name',
    address: 'Address',
    phone: 'Phone',
    time: 'Time',
    email: 'Email',
    content: 'Content',
    selectType: 'Please select a new type',
    示例文件: 'Sample File',
    title: 'Title',
    preview: 'Preview',
    type: 'Type',
    status: 'State',
    createTime: 'Create Time',
  },
  companyInfo: {
    name: 'STAR.VISION AEROPSPACE GROUP LIMITED',
    address: 'Address: 4 / F, Building A, Cali Town, Hangzhou, China',
    phone: 'Phone: 0571-86227683',
    worktime: 'Time: Weekdays 10:00-19:00',
    email: 'Email: SV@STAR.VISION'
  },
  case: {
    推荐案例: 'Recommended',
  },
  experience: {
    experienceLabel: 'Create a belong to your own 3D model',
    experienceLabel2: 'For example, Building A, Cali Town, Hangzhou',
    exampleFile: 'Trucks',
    sidebar: {
      仪表盘: 'The dashboard',
      我的项目: 'My project',
      最近使用: 'Recently used',
      我的收藏: 'My collection',
      回收站: 'Recovery station',
    },
    outLabel: 'Create a belong to your own 3D model',
    nameInputHode: 'For example, Hangzhou Cali Town',
    typeLabel: 'Please select a new type',
    uploadLabel: 'Drag the file here, or',
    imageTableTitle: 'Photo Gallery',
    uploadChackText: 'Automatic modeling after upload'
  },
}
