/*
 * @Author: Coan
 * @Date: 2023-05-07 03:24:05
 * @LastEditors: Coan
 * @LastEditTime: 2023-05-19 18:55:18
 * @FilePath: /rebuild_web_new/src/api/login.js
 * @Description:
 */

import request from '@/utils/request'

// 登陆
export function loginCommon(data) {
  return request({
    url: 'api/blade-client/login',
    method: 'POST',
    data
  })
}
// 验证码注册
export function registerWithCode(data) {
  return request({
    url: 'api/blade-client/verificationCodeRegistration',
    method: 'POST',
    data
  })
}
// 发送验证码
export function sendCode(data) {
  return request({
    url: 'api/blade-login/phone/tenXunApi/phoneCode',
    method: 'POST',
    data
  })
}
// 发送验证码-注册
export function sendCode_Login(data) {
  return request({
    url: 'api/blade-login/phone/tenXunApi/sendMobilePhoneNumberRegistration',
    method: 'POST',
    data
  })
}
// 发送验证码-修改信息
export function sendCode_Info(data) {
  return request({
    url: 'api/blade-login/phone/tenXunApi/determineAndSendTheVerificationCode',
    method: 'POST',
    data
  })
}
// 微信登录
export function weChatLogin(params) {
  return request({
    url: 'api/blade-login/weChat/scanCode',
    method: 'GET',
    params
  })
}
// 绑定手机号
export function wechatBindPhone(data) {
  return request({
    url: 'api/blade-login/weChat/bindPhone',
    method: 'POST',
    data
  })
}
// 获取用户信息
export function selectUserInfo(phone) {
  return request({
    url: 'api/blade-client/selectPhone?phone=' + phone,
    method: 'GET',
  })
}
// 修改用户头像
export function updateAvatar(data) {
  return request({
    url: 'api/blade-client/updateAvatar',
    method: 'PUT',
    data
  })
}
// 修改用户信息
export function updateBasicInformation(data) {
  return request({
    url: 'api/blade-client/updateBasicInformation',
    method: 'PUT',
    data
  })
}
// 修改用户密码
export function changePassword(data) {
  return request({
    url: 'api/blade-client/changePassword',
    method: 'PUT',
    data
  })
}
// 退出登录
export function logout() {
  return request({
    url: 'api/blade-auth/oauth/logout',
    method: 'GET',
  })
}
// 查询手机号是否被注册
export function phoneReged(phone) {
  return request({
    url: 'api/blade-client/accordingToThePhoneNumberQuery?phone=' + phone,
    method: 'GET',
  })
}
// 上传文件
export function simpleSingleFile(data) {
  return request({
    url: 'api/system/file/simpleSingle',
    method: 'POST',
    data
  })
}