/*
 * @Author: Coan
 * @Date: 2023-04-12 14:54:13
 * @LastEditors: Coan
 * @LastEditTime: 2023-04-13 10:30:35
 * @FilePath: /rebuild_web_new/src/i18n/index.js
 * @Description:
 */
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import elementEnLocale from 'element-ui/lib/locale/lang/en' // element-ui lang
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN'// element-ui lang
import enLocale from './en'
import zhLocale from './zh'
// import { getStore } from '@/util/store'
Vue.use(VueI18n)
const messages = {
  en: {
    ...enLocale,
    ...elementEnLocale,
  },
  zh: {
    ...zhLocale,
    ...elementZhLocale,
  }
}

const i18n = new VueI18n({
  // getStore({ name: 'language' }) ||
  locale: localStorage.getItem('i18n') || 'zh',
  messages
})

export default i18n