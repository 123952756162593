/*
 * @Author: Coan
 * @Date: 2023-04-17 16:18:52
 * @LastEditors: Coan
 * @LastEditTime: 2023-05-19 17:09:39
 * @FilePath: /rebuild_web_new/src/utils/element.js
 * @Description:
 */

import Vue from 'vue'
import {
  Input, Dropdown, DropdownItem, DropdownMenu, Button, Image, Row, Col, Popover, Upload, Table, TableColumn, Tag, Menu, MenuItem, Carousel, CarouselItem, MessageBox, Message, Checkbox, Dialog, Avatar, Pagination, Tabs, TabPane
  // -
  // Form, FormItem,
  // Container, Header, Aside, Main, Submenu,
  // Breadcrumb, BreadcrumbItem, Card,
  // Switch, Tooltip,
  // Tree, Select,
  // Option, Cascader, Alert, Steps, Step,
  // CheckboxGroup, Timeline, TimelineItem,
} from 'element-ui'

Vue.use(Input)
Vue.use(Dropdown)
Vue.use(DropdownItem)
Vue.use(DropdownMenu)
Vue.use(Button)
Vue.use(Image)
Vue.use(Row)
Vue.use(Col)
Vue.use(Popover)
Vue.use(Upload)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Tag)
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Checkbox)
Vue.use(Dialog)
Vue.use(Avatar)
Vue.use(Pagination)
Vue.use(Tabs)
Vue.use(TabPane)
// -
// Vue.use(Form)
// Vue.use(FormItem)
// Vue.use(Container)
// Vue.use(Header)
// Vue.use(Aside)
// Vue.use(Main)
// Vue.use(Submenu)
// Vue.use(Breadcrumb)
// Vue.use(BreadcrumbItem)
// Vue.use(Card)
// Vue.use(Switch)
// Vue.use(Tooltip)
// Vue.use(Tree)
// Vue.use(Select)
// Vue.use(Option)
// Vue.use(Cascader)
// Vue.use(Alert)
// Vue.use(Steps)
// Vue.use(Step)
// Vue.use(CheckboxGroup)
// Vue.use(Timeline)
// Vue.use(TimelineItem)
// 这个特殊的是message, 需要全局挂载  弹窗
Vue.prototype.$message = Message
Vue.prototype.$confirm = MessageBox.confirm