/*
 * @Author: Coan
 * @Date: 2023-04-10 10:23:44
 * @LastEditors: Coan
 * @LastEditTime: 2023-05-22 14:54:15
 * @FilePath: /rebuild_web_new/src/utils/request.js
 * @Description:
 */
import { Message } from 'element-ui';
import axios from 'axios'
import store from '@/store'
import { logout } from "@/api/login";

const REQUEST_BASE_URL = '/'

const service = axios.create({
  baseURL: REQUEST_BASE_URL,
  timeout: 2000000,
})

// 请求拦截器
service.interceptors.request.use(
  config => {
    // console.log(config.url)
    // let DV2APIS = ['/createTask', '']
    // if (DV2APIS.indexOf(config.url) + 1) config.baseURL = 'http://120.27.217.146:8765'
    // do something before request is sent
    config.headers.Authorization = 'Basic c2FiZXI6c2FiZXJfc2VjcmV0'
    config.headers['Tenant-Id'] = '000000'
    config.headers['language'] = localStorage.getItem('i18n')
    config.headers['Access-Control-Allow-Origin'] = '*';
    config.withCredentials = false
    config.crossDomain = true

    const user = store.getters.LOGININFO || {}
    if (user.access_token) {
      config.headers['Blade-Auth'] = 'bearer ' + user.access_token
    }
    return config
  },
  error => {
    // do something with request error
    // console.log(error) // for debug
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    console.log(res)
    if (!res) Promise.reject(new Error(msg || 'Error'))
    if (res.code === 0 && res.msg === '成功' || res.code === 200) {
      return res.data
    }
    // if the custom code is not 200, it is judged as an error.
    if (parseInt(res.data.code) !== 200 || res.data.code !== 200) {
      Message({
        message: res.data.data || res.data,
        duration: 1000,
        type: 'warning'
      })
      if (res.data.code === 401) {
        Message({
          message: '登陆验证已过期，请重新登陆！',
          duration: 1000,
          type: 'warning'
        })
      }
      if (res.data.code === 450) {
        const error = {
          code: res.code,
          msg: res.msg
        }
        return Promise.reject(error)
      }
      const { msg } = res
      return Promise.reject(new Error(msg || 'Error'))
    }
  },
  error => {
    const { response } = error || {}
    const { data } = response || {}
    console.log(data)
    Message({
      message: data.msg,
      duration: 1000,
      type: 'warning'
    })
    if (data.code === 401) {
      logout().then((res) => {
        store.dispatch("LOGOUT");
      });
    }
    return Promise.reject(data)
  }
)

export default service
