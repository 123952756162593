/*
 * @Author: Coan
 * @Date: 2023-04-10 10:23:44
 * @LastEditors: Coan
 * @LastEditTime: 2023-05-22 04:04:53
 * @FilePath: /rebuild_web_new/src/store/index.js
 * @Description:
 */
import Vue from 'vue'
import Vuex from 'vuex'
import {
  selectUserInfo,
} from "@/api/login";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    dictionary: '',
    LOGININFO: {},
    USERINFO: {},
    LOGIN_STATUS: false,
    MODELKEYWORD: ''
  },
  getters: {
    dictionary: state => state.dictionary,
    LOGININFO: state => state.LOGININFO,
    USERINFO: state => state.USERINFO,
    GET_LOGIN_STATUS: state => state.LOGIN_STATUS
  },
  mutations: {
    /**
     * 设置字典
     * */
    setDict(state, data) {
      state[data.code] = data.data
    },
    SET_LOGIN_INFO(state, value) {
      state.LOGININFO = value
    },
    SET_USER_INFO(state, value) {
      state.USERINFO = value
    },
    // 登陆状态改变
    SET_LOGIN_STATUS(state, value) {
      state.LOGIN_STATUS = value
    }
  },
  actions: {
    LOGIN({ commit }, value) {
      commit('SET_LOGIN_INFO', value)
      if (value.account) {
        selectUserInfo(value.account).then(res => {
          commit('SET_USER_INFO', res)
          commit('SET_LOGIN_STATUS', true)
        })
      }
    },
    LOGOUT({ commit }) {
      commit('SET_LOGIN_INFO', {})
      commit('SET_USER_INFO', {})
      commit('SET_LOGIN_STATUS', false)
      localStorage.removeItem('store')
    },
  },
  modules: {}
})
