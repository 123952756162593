/*
 * @Author: Coan
 * @Date: 2023-04-12 14:56:33
 * @LastEditors: Coan
 * @LastEditTime: 2023-05-10 11:42:11
 * @FilePath: /rebuild_web_new/src/i18n/zh.js
 * @Description:
 */
export default {
  官网: '官网',
  qrcode: {
    server: '服务号',
    company: '企业微信',
    service: '扫码添加客服微信',
    serviceQ: '联系QQ'
  },
  photoList: '照片列表',
  photoSize: '照片大小',
  automaticModelingTip: '上传完成后自动建模',
  // 联系客服提示
  service: {
    customerText1: '要获得更快的上传速度和更优质的模型效果，请联系客服，扫描下方二维码添加微信或添加QQ进行沟通，感谢您的配合！',
    contentTips: '要获得更快的上传速度和更优质的模型效果，请联系客服，扫描下方二维码添加微信或添加QQ：2867488797进行沟通，感谢您的配合！',
    bottomTips: '请扫码添加客服微信或联系QQ：2867488797'
  },
  nav: {
    案例中心: '案例中心',
    解决方案: '解决方案',
    体验入口: '体验入口',
    联系我们: '联系我们',
    合作伙伴: '合作伙伴',
    语言: '语言',
    搜索占位: '搜索你想要的3D模型'
  },
  notice: {
    unbound: '正在努力构建中，请耐心等待！',
    requiredValue: '请填写必填字段！',
    success: '操作成功，请耐心等待！'
  },
  language: {
    中文: '中文',
    英文: 'ENGLISH'
  },
  btns: {
    去体验: '去体验',
    查看更多: '查看更多',
    马上去体验: '马上去体验',
    提交: '提交',
    创建模型: '创建模型',
    下一步: '下一步',
    上一步: '上一步',
    上传: '上传',
    点击上传: '点击上传'
  },
  status: {
    准备中: '准备中',
    构建中: '构建中',
    构建成功: '构建成功',
    构建失败: '构建失败',
    全部: '全部'
  },
  filters: {
    只看星标: '只看星标',
    处理列表: '处理列表',
    终端: '终端',
    状态: '状态',
    排序: '排序',
    列表: '列表',
  },
  label: {
    name: '姓名',
    companyName: '公司名称',
    address: '地址',
    phone: '电话',
    time: '时间',
    email: '邮箱',
    content: '内容',
    selectType: '请选择新建类型',
    示例文件: '示例文件',
    title: '标题',
    preview: '缩略图',
    type: '类型',
    status: '状态',
    createTime: '创建时间',
  },
  companyInfo: {
    name: '地卫二空间技术（杭州）有限公司',
    address: '中国（杭州）算力小镇A座4楼',
    phone: '0571-86227683',
    worktime: '工作日 10:00-19:00',
    email: 'SV@STAR.VISION'
  },
  case: {
    推荐案例: '推荐案例',
  },
  experience: {
    experienceLabel: '创建一个属于你自己的三维模型吧～',
    experienceLabel2: '例如：杭州算力小镇A幢',
    exampleFile: '卡车',
    sidebar: {
      仪表盘: '仪表盘',
      我的项目: '我的项目',
      最近使用: '最近使用',
      我的收藏: '我的收藏',
      回收站: '回收站',
    },
    outLabel: '创建一个属于你自己的三维模型吧',
    nameInputHode: '例如：杭州算力小镇',
    typeLabel: '请选择新建类型',
    uploadLabel: '将文件拖到此处，或',
    imageTableTitle: '照片列表',
    uploadChackText: '上传完成后自动建模'
  },
}
