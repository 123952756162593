<template>
  <div id="app">
    <router-view></router-view>
    <el-popover
      placement="left"
      width="280"
      trigger="hover"
      popper-class="floatWarp"
    >
      <div class="floatWarp">
        <div class="text">{{ $t(`service.customerText1`) }}</div>
        <div class="qrCodeWarp">
          <div class="qrCodeItem">
            <el-image class="qrCodeImg" :src="service"></el-image>
            <p class="label">{{ $t(`qrcode.service`) }}</p>
          </div>
          <div class="qrCodeItem">
            <el-image class="qrCodeImg" :src="serviceQ"></el-image>
            <p class="qqNum">2867488797</p>
            <p class="label">{{ $t(`qrcode.serviceQ`) }}</p>
          </div>
        </div>
      </div>
      <div class="floatBtn" slot="reference" v-show="showFloatBtn">
        <img src="@/assets/icon/customer.png" alt="" srcset="" />
      </div>
    </el-popover>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showFloatBtn: false,
      service:
        "https://nerf-test.oss-cn-hangzhou.aliyuncs.com/upload/20230418/c024d8b84f638f1b44855f98001676ea.png",
      serviceQ:
        "https://nerf-test.oss-cn-hangzhou.aliyuncs.com/upload/20230418/b1d86c8d20140cf7bf2e1632e2086654.png",
    };
  },
  watch: {
    $route(value) {
      let meta = value.meta;
      if (meta.showFloatBtn) this.showFloatBtn = true;
      else this.showFloatBtn = false;
    },
  },
  created() {
    //在页面加载时读取sessionStorage里的状态信息
    if (sessionStorage.getItem("store")) {
      this.$store.replaceState(
        Object.assign(
          {},
          this.$store.state,
          JSON.parse(sessionStorage.getItem("store"))
        )
      );
    }

    //在页面刷新时将vuex里的信息保存到sessionStorage里
    window.addEventListener("beforeunload", () => {
      sessionStorage.setItem("store", JSON.stringify(this.$store.state));
    });
  },
};
</script>

<style lang="scss">
li {
  list-style: none;
}
.floatBtn {
  width: 72px;
  height: 72px;
  border-radius: 50%;
  background-color: #0090ff;
  position: fixed;
  right: 40px;
  bottom: 480px;
  img {
    display: block;
    width: 32px;
    height: 32px;
    margin: 0 auto;
    margin-top: 50%;
    transform: translateY(-50%);
  }
}
.el-popover.floatWarp {
  display: flex;
  flex-direction: column;
  .qrCodeWarp {
    width: 260px;
    display: flex;
    justify-content: space-between;

    .qrCodeItem {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;

      .qrCodeImg {
        width: 110px;
        height: 110px;
      }

      .label {
        color: #727171;
        font-size: 14px;
        text-align: center;
      }

      .qqNum {
        width: 100%;
        text-align: center;
        position: absolute;
        top: 40%;
      }
    }
  }
}
</style>
