/*
 * @Author: Coan
 * @Date: 2023-04-10 10:23:44
 * @LastEditors: Coan
 * @LastEditTime: 2023-05-22 03:44:50
 * @FilePath: /rebuild_web_new/src/main.js
 * @Description:
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueSession from 'vue-session'
import './utils/message'

router.afterEach((to, from, next) => {
  window.scrollTo(0, 0); //切换路由之后滚动条始终在最顶部
});

import 'element-ui/lib/theme-chalk/index.css'
// import ElementUI from 'element-ui'
import './utils/element'
import i18n from './i18n'

import './styles/common.css'
import './styles/margin.css'
import './styles/flex.css'

const axios = require('axios')
Vue.prototype.$axios = axios
// Vue.prototype.$t = i18n.global.t
// console.log(Vue.prototype)

Vue.use(VueSession)
Vue.config.productionTip = false
Vue.use({
  i18n: (key, value) => i18n.t(key, value)
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
